import React, {useEffect} from "react";
import Header from "./Header";
import {useDispatch, useSelector} from "react-redux";
import {getAttemptList} from "../actions";
import AttemptList from "./AttemptList";

export default function Attempts(props) {
    const contentId = props.match.params.contentId;
    let user = useSelector((store) => store.user);
    let attemptList = useSelector((store) => store.attemptList);
    let dispatch = useDispatch();
    useEffect(() => {
        if (user !== null) {
            dispatch(getAttemptList(user, contentId))
        }
    }, [user, contentId]);

    return (
        <div>
            <Header/>
            <AttemptList list={attemptList}/>
        </div>
    );
}