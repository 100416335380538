import React, {useEffect} from "react";
import Header from "./Header";
import {useDispatch, useSelector} from "react-redux";
import {getAssignmentList} from "../actions";
import AssignmentList from "./AssignmentList";

export default function Dashboard() {
    let user = useSelector((store) => store.user);
    let assignmentList = useSelector((store) => store.assignmentList);
    let dispatch = useDispatch();
    useEffect(() => {
        if (user !== null) {
            dispatch(getAssignmentList(user))
        }
    }, [user]);

    return (
        <div>
            <Header/>
            <AssignmentList list={assignmentList}/>
        </div>
    );
}