import User from "../../model/User";
import {SIGN_IN, SIGN_OUT} from "../../actions";


export default function (state = null, action) {
    switch (action.type) {
        case SIGN_IN:
            if (!action.error) {
                return User.fromResponse(action.payload);
            }
            return state;
        case SIGN_OUT:
            return null;
        default:
            return state;
    }
}
