import moment from "moment/moment";

export default class Attempt {
    created;
    success;
    text;
    message;

    constructor(created, success, text, message) {
        this.created = created;
        this.success = success;
        this.text = text;
        this.message = message;
    }

    static fromResponse(response) {
        return new Attempt(moment(response.created), response.success, response.text, response.message)
    }
}