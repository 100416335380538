import React, {useEffect} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Link} from "@material-ui/core";
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBarLink: {
        color: "white"
    },

}));

export default function Header() {
    const classes = useStyles();
    const user = useSelector((state) => state.user);
    const history = useHistory();
    useEffect(() => {
        if (user == null) {
            history.push('/login')
        }
    }, [user]);
    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <Link className={classes.appBarLink} component={RouterLink} to={"/"}>
                    <Typography variant="h6" color="inherit">
                        Задания
                    </Typography>
                </Link>
            </Toolbar>
        </AppBar>
    );
}