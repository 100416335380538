import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import UserReducer from "./UserReducer";
import ErrorReducer from "./ErrorReducer";
import AssignmentListReducer from "./AssignmentListReducer";
import AttemptListReducer from "./AttemptListReducer";


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    error: ErrorReducer,
    assignmentList: AssignmentListReducer,
    attemptList: AttemptListReducer,
});

export default createRootReducer

