import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    headCell: {
        fontWeight: "bold",
    },
    actionLink: {
        marginLeft: 20,
        cursor: "pointer"
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const headCells = [
    {id: "result", label: "Результат", props: {align: "center"}},
    {id: "created", label: "Дата", props: {align: "center"}},
    {id: "message", label: "Сообщение", props: {align: "left"}},
    {id: "text", label: "Текст", props: {align: "left"}},
];

function getCompletedIcon(completed) {
    switch (completed) {
        case null:
            return null;
        case true:
            return (<CheckCircleIcon/>);
        case false:
            return (<CancelIcon/>);
        default:
            return null;
    }
}

export default function AssignmentList(props) {
    const classes = useStyles();
    console.log("list", props.list)
    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="assignments table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((cell) =>
                                <TableCell className={classes.headCell} key={cell.id} {...cell.props} >
                                    {cell.label}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.list
                            .map((row, idx) =>
                                <TableRow key={idx}>
                                    <TableCell align="center" style={{width: 120}}>
                                        {getCompletedIcon(row.success)}
                                    </TableCell>
                                    <TableCell align="center" style={{width: 120}}>
                                        {row.created.format("DD.MM.YYYY HH:mm:ss")}
                                    </TableCell>
                                    <TableCell align="left" style={{width: 200}}>
                                        {row.message}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.text}
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}