import {SIGN_IN, SIGN_OUT} from "../../actions";


export default function (state = null, action) {
    switch (action.type) {
        case SIGN_IN:
            if (action.error) {
                if (action.payload?.response?.status === 401) {
                    return {message: "Не правильное имя пользователя или пароль."}
                }
                return {message: "Ошибка аутентификации, повторите попытку позже."}
            }
            return state;
        case SIGN_OUT:
            return null;
        default:
            return state;
    }
}
