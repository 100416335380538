import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Paper, Table, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from "react-redux";

const ltiSchema = process.env.REACT_APP_LTI_SCHEMA;
const ltiPath = process.env.REACT_APP_LTI_PATH;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    headCell: {
        fontWeight: "bold",
    },
    actionLink: {
        marginLeft: 20,
        cursor: "pointer"
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const headCells = [
    {id: "label", label: "Название", props: {align: "left"}},
    {id: "result", label: "Результат", props: {align: "center"}},
];

function getCompletedIcon(completed) {
    switch (completed) {
        case null:
            return null;
        case true:
            return (<CheckCircleIcon/>);
        case false:
            return (<CancelIcon/>);
        default:
            return null;
    }
}

export default function AssignmentList(props) {
    const classes = useStyles();
    let user = useSelector((store) => store.user);

    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="assignments table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((cell) =>
                                <TableCell className={classes.headCell} key={cell.id} {...cell.props}>
                                    {cell.label}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.list
                            .map(row =>
                                <TableRow key={row.id}>
                                    <TableCell align="left">
                                        <a className={classes.actionLink}
                                           href={`${ltiSchema}://${ltiPath}/${row.id}`}
                                           target={"_blank"}
                                           rel={"noopener noreferrer"}
                                        >{row.label}</a>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.completed !== null &&
                                        <Link component={RouterLink} className={classes.actionLink}
                                              to={`attempts/${row.id}`}>
                                            {getCompletedIcon(row.completed)}
                                        </Link>
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}