export default class User {
    id;
    username;
    password;


    constructor(id, username, password) {
        this.id = id;
        this.username = username;
        this.password = password;
    }

    static fromResponse(response) {
        return new User(response.id, response.username, response.password)
    }
}