import axios from 'axios';
import {api} from '../constants/api';

export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const ASSIGNMENT_LIST = "ASSIGNMENT_LIST";
export const ATTEMPT_LIST = "ATTEMPT_LIST";


export function signIn(username, password, callback) {
    const request = axios
        .get(`${api}/login`, {auth: {username, password}})
        .then((response) => {
            return {...response.data, password};
        });
    return {
        type: SIGN_IN,
        payload: request
    }
}

export function getAssignmentList(user) {
    const request = axios.get(`${api}/assignments`, {auth: {username: user.username, password: user.password}});
    return {
        type: ASSIGNMENT_LIST,
        payload: request
    }
}

export function getAttemptList(user, contentId) {
    const request = axios.get(`${api}/attempts/${contentId}`, {
        auth: {
            username: user.username,
            password: user.password
        }
    });
    return {
        type: ATTEMPT_LIST,
        payload: request
    }
}
