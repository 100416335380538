import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import {Container, CssBaseline} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/core/styles';

import configureStore, {history} from "./redux/configureStore";
import theme from './theme';
// import AssignmentList from "./components/AssignmentList";
// import AssignmentEditor from "./components/AssignmentEditor";
import Dashboard from "./components/Dashboard";
import SignIn from "./components/SignIn";
import Attempts from "./components/Attempts";

const rootElement = document.getElementById('root');
const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Container>
                    <Switch>
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route exact path="/attempts/:contentId" component={Attempts}/>
                        <Route exact path="/login" component={SignIn}/>
                        <Route component={SignIn}/>
                        <Route render={() => <div>"Не Найден"</div>}/>
                    </Switch>
                </Container>
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    rootElement
);
