import {ATTEMPT_LIST} from "../../actions";
import Attempt from "../../model/Attempt";

export default function (state = [], action) {
    switch (action.type) {
        case ATTEMPT_LIST:
            return action.payload?.data?.map?.(Attempt.fromResponse);
        default:
            return state;
    }
}
