import {ASSIGNMENT_LIST} from "../../actions";

export default function (state = [], action) {
    switch (action.type) {
        case ASSIGNMENT_LIST:
            return action.payload.data;
        default:
            return state;
    }
}
